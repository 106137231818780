<template>
  <div class="card-content table-card-content">
    <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    <small v-if="pspdList.length > 0" class="page-info is-pulled-right"
      ><strong>{{ pagination.startRowNum }}</strong> -
      <strong>{{ pagination.startRowNum + pspdList.length - 1 }}</strong>
      dari <strong>{{ pagination.total }}</strong></small
    >
    <div class="cleardiv"></div>
    <box
      v-for="pspd in pspdList"
      :key="pspd.id"
      :title="pspd.nama"
      :subtitle1="pspd.npm"
      :detailUrl="{ name: 'anggota-detail', params: {id: pspd.id} }"
    >
      <template v-if="pspd.inisial" v-slot:date>
        <span class="is-pulled-right has-text-grey">
          <small class="tanggal">{{pspd.inisial}}</small>
        </span>
      </template>
    </box>
    <box v-if="pspdList.length == 0">
      <template v-slot:content>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Belum ada mahasiswa yang terdaftar pada angkatan ini.</p>
          </div>
        </section>
      </template>
    </box>
    <!-- <b-table bordered striped hoverable :data="pspdList" :mobile-cards="false">
      <b-table-column field="no" label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
      <b-table-column field="npm" label="NPM" v-slot="props">{{ props.row.npm }}</b-table-column>
      <b-table-column field="inisial" label="Inisial" v-slot="props">{{ props.row.inisial }}</b-table-column>
      <b-table-column field="pspd" label="Mahasiswa" v-slot="props">
        <router-link
          :to="{ name: 'anggota-detail', params: {id: props.row.id} }"
        >{{ props.row.nama }}</router-link>
      </b-table-column>

      <template slot="empty">
        <div class="content has-text-grey has-text-centered">
          <p>Belum ada mahasiswa yang terdaftar pada angkatan ini.</p>
        </div>
      </template>
    </b-table>-->
    <b-pagination
      v-if="pagination.showPagination"
      :total="pagination.total"
      :per-page="pagination.limit"
      :current.sync="pagination.page"
      order="is-centered"
      icon-pack="fas"
      @change="pageChange"
    ></b-pagination>
    <!-- <b-loading :is-full-page="false" :active.sync="loading"></b-loading> -->
  </div>
</template>

<script>
import PSPDList from "../models/pspdList.js";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";

export default {
  name: "PspdTable",
  props: {
    angkatan: null,
  },
  mixins: [onlineCheckMixin, paginationMixin],
  data() {
    this.listMdl = new PSPDList(this.angkatan.id);
    return this.listMdl.getObservables();
  },
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
  },
  methods: {
    fetchData() {
      this.listMdl.load(this.onLoaded);
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="scss">
.page-info {
  margin-top: 10px;
  margin-right: 10px;
}

.cleardiv {
  clear: both;
}
</style>