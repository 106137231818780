import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "pspdList";
const modelPath = "/programstudi/angkatan/pspd/";

class PSPDList extends ListModelBase {
  constructor(angkatanId) {
    super(modelName, modelPath, {angkatan_id: angkatanId});
  }
}

export default PSPDList;
